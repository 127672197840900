* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overscroll-behavior-y: contain;
  height: 100%;
}

a {
  cursor: pointer;
}

#wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#wrapperMain {
  flex: 1 0 auto;
}

#wrapperHeader {
  flex-shrink: 0;
}

#wrapperFooter {
  flex-shrink: 0;
}

.welcomeBatsmanImg {
  max-width: 100%;
  width: 100%;
  height: 40vh;
}

.is-sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  background: #ffffff;
}

.welcomeOrangeTxt {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  color: #a51e22;
}

.cricketAppTxt {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  text-transform: uppercase;
  color: #a51e22;
}

.cricketAppTxtReg {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #a51e22;
}

.paraTxt {
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  text-align: justify;
}

.loginbtn {
  background-color: #3c3c40;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
}

.Partnerbtn {
  background-color: #000000;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
}

.Registerbtn {
  background-color: #a51e22;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
}

.statusButtons {
  border-radius: 6px;
  padding: 4px 0px;
  color: white;
  border: none;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 12px;
}

.custom-checkbox .form-check-input[type="checkbox"] {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border: 2px solid #3c3c40 !important;
}

.matchStatusButtons {
  border-radius: 6px;
  padding: 7px 10px;
  color: white;
  border: none;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 14px;
}

.upcomingbtn {
  background-color: #3c3c40;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  text-decoration: none;
  width: 100%;
}

.bodyClass {
  background-image: url("../../public/images/FlashScreen.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.cricketLogoFlash {
  height: 170.4px;
  max-width: 100%;
  position: absolute;
  top: 30%;
}

.mainDiv {
  background-color: #f4f4f4;
  width: 100%;
  position: relative;
}

.avtarImg {
  max-width: 100%;
  height: auto;
  display: flex;
  margin: auto;
}

.formHeading {
  font-size: 21px;
  line-height: 30px;
  color: #3c3c40;
  font-style: normal;
  font-weight: 500;
  font-family: "Poppins", sans-serif !important;
}

.formControl {
  background: #e8e8e8 !important;
  opacity: 0.85 !important;
  border-radius: 5px !important;
  padding: 12px 12px 12px 40px !important;
  border: none !important;
}

.bottomImg {
  bottom: 0;
  position: absolute;
}

.inputIcons {
  position: absolute;
  z-index: 2;
  top: 23%;
  left: 10%;
  max-width: 100%;
  height: auto;
}

.inputIcons1 {
  position: absolute;
  z-index: 2;
  top: 25%;
  left: 3%;
  max-width: 100%;
  height: auto;
}

.HeadingFormDiv {
  height: 25vh;
}

.centerFormDiv {
  position: absolute;
}

.suggBox {
  display: none;
  position: absolute;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  left: 2px;
  right: 2px;
  background: white;
  overflow: scroll;
  box-shadow: 0px 0px 5px 0px grey;
  border-radius: 5px;
}

input[disabled] {
  pointer-events: none;
}

.eyeIcon {
  position: absolute;
  z-index: 2;
  top: 25%;
  right: 3%;
  max-width: 100%;
  height: auto;
}

.forgotTxt {
  color: #656565;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}

.posRel {
  position: relative;
}

.errorTxt {
  font-weight: bold;
  color: #3c3c40;
  text-decoration: none;
  font-family: "Poppins", sans-serif !important;
}

.NavbarClass {
  width: 100%;
  top: 0;
  background: #f3f3f3;
  position: sticky !important;
  z-index: 1;
}

.LogoImage {
  height: 9vh;
  max-width: 100%;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.ml-auto {
  margin-left: auto;
}

.searchInput {
  background-color: white !important;
  color: black !important;
  opacity: 1.5 !important;
}

.nav-link {
  color: black !important;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}

.footerClass {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  background: #f3f3f3;
  height: 72px;
}

.blackIcons {
  color: black;
  text-decoration: none;
}

.activeClass {
  color: #3c3c40 !important;
}

.mainBody {
  background-color: #f4f4f4;
  height: 100vh;
  padding-top: 98px;
}

.interior {
  padding-top: 0;
}

.videoClip {
  border-radius: 0px 0px 10px 10px;
  max-width: 100%;
  width: 100%;
}

.availableMatchDiv {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  height: 127.52px;
  cursor: pointer;
}

.availableMatchTxtDiv {
  font-family: "Poppins";
  font-weight: 550;
  font-size: 16px;
  color: #525252;
}

.profileformControl {
  background: #e8e8e8 !important;
  border-radius: 5px !important;
  padding: 12px 15px 12px 15px !important;
  border: none !important;
}

.datePickerIcon {
  position: absolute;
  z-index: 2;
  top: 51%;
  right: 55%;
}

.dummyavtar {
  width: 30%;
  height: auto;
  border-radius: 50%;
}

.clsTxtColor {
  padding-top: 2px;
  color: #3c3c40 !important;
}

.form-select {
  line-height: 2 !important;
  color: #3c3c40 !important;

  border: none !important;
}

.txtGray {
  color: #625b51;
}

.loader-view {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.mainContainer {
  margin-top: 3.8rem !important;
}

.textLabel {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: left;
  color: #000000;
}

.form-control:focus {
  border: 0.5px solid #3c3c40 !important;
  box-shadow: none !important;
}

.form-check-input {
  border: 0.5px solid #3c3c40 !important;
}

.form-check-input:checked {
  background-color: #3c3c40 !important;
  border-color: #3c3c40 !important;
}

.form-check-input:focus {
  border-color: #3c3c40 !important;
  outline: 0;
  box-shadow: none !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-control {
  color: #3c3c40 !important;
}

.form-select {
  border: 0.1px solid #d2d2d2 !important;
}

.indexedStep {
  color: #3c3c40;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #3c3c40;
  color: white;
}

.RSPBprogressBar .RSPBprogression {
  background: #3c3c40 !important;
}

.validationText {
  color: red;
  font-size: 10px;
  z-index: 9999;
  right: 0px;
  float: right;
}

::placeholder {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(15, 16, 16, 0.7) !important;
}

.centerAlign {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.centerAlignhome {
  display: flex !important;
  align-items: center !important;
  justify-content: left;
}

.form-select::selection {
  background-color: #000000;
}

.RSPBprogressBar {
  margin: 15px !important;
  margin-bottom: 0px !important;
}

.alignModalCenter {
  margin-top: 50% !important;
}

.disabledClass {
  cursor: none;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #333;
  background-color: #f3f3f3;
}

.dataText {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: left;
  color: #a51e22;
}

.venueText {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins" !important;
  color: black !important;
  text-align: justify;
}

.venueText1 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins" !important;
  color: #a51e22 !important;
  letter-spacing: 1px;
}

.contactInfo {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins" !important;
  color: #3c3c40 !important;
}

.venueListingTop {
  border: none;
  border-radius: 5px;
  background: #3c3c40;
  color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "Poppins" !important;
}

.venuePlaceCard {
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  background: #f7f7f7;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sportsAmenities {
  height: 20px;
}

.getdays {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #a51e22;
}

.groundName {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  color: #000000;
}

.paymentTitle {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-transform: uppercase;
}

.userName {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.matchName {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
}

.playerProfilePhoto {
  position: relative;
  height: 120px;
  width: 120px;
  margin: 14px;
  background-color: #e8e8e8;
  border-radius: 60px;
  overflow: hidden;
  z-index: 99999;
}

.profileContainer {
  border-radius: 15px 15px 5px 5px !important;
}

.profileCross {
  padding: 10px;
  float: right;
}

.mobile {
  display: none;
}

@media only screen and (min-width: 768px) {
  .booknowDiv {
    bottom: -20%;
  }

  .mainContainer {
    overflow: scroll;
  }

  .desktop {
    z-index: 999;
    background-color: white;
  }

  .firstpageimage {
    max-width: 30%;
    height: auto;
  }

  .secondpageimage {
    max-width: 25%;
    height: auto;
  }
}

#filtersCard {
  z-index: 1;
  right: 0px;
  top: 45px;
}

#filtersCard1 {
  z-index: 9;
  bottom: 0;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.mainContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MainLayoutMargin {
  padding-top: 70px;
}

.timebox {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.signuppolicy {
  color: #a51e22;
}

.signuppolicy a {
  text-decoration: none;
  color: #a51e22;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .l-40 {
    left: 20% !important;
  }
  .scrollpopularsports {
    overflow: auto !important;
  }

  .campainDiv {
    color: #e2e2eb;
    font-size: smaller !important;
  }

  .campainDiv button {
    color: #e2e2eb;
    font-size: smaller !important;
  }

  .bottom-5 {
    bottom: 7% !important;
  }

  .venueListingH1 {
    font-size: 16px !important;
  }

  .venueOwnerLabel {
    font-size: 18px !important;
  }

  .newLandingPage h2 {
    font-size: 18px !important;
  }

  .newLandingPage h3 {
    font-size: 16px !important;
  }

  input {
    font-size: 16px !important;
  }

  textarea {
    font-size: 14px !important;
  }

  select {
    font-size: 14px !important;
  }

  .mainContainer {
    overflow: scroll;
    margin-top: 3.8rem !important;
  }

  .mobile {
    display: block;
  }

  .destop {
    display: none;
  }

  .editImage {
    height: 25px;
    width: 25px;
  }

  .editicon {
    width: 100%;
  }

  .sticky-button {
    position: fixed;
    z-index: 100;
    bottom: 0px;
  }

  .venuePhotos {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 173px;
    border-radius: 16px 16px 0px 0px;
    object-fit: cover;
  }

  .venuePhotos1 {
    max-height: 370px;
  }

  .venuePhotos3 {
    height: 280px !important;
  }

  .heartIcon {
    width: 2rem;
    float: right;
    height: 30px;
    position: absolute;
    top: 67px;
    right: 38px;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .heartImage {
    width: 2rem;
    float: right;
    height: 30px;
    position: absolute;
    top: -24px;
    right: 17px;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  #checkboxContainer {
    z-index: 1;
    top: 37px;
    left: 154px;
  }

  .dayPartOpen {
    background: white;
    border-radius: 8px;
    border: none;
    padding: 19px;
    height: 245px;
    width: 150px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .firstpageimage {
    max-width: 100%;
    height: auto;
  }

  .secondpageimage {
    max-width: 75%;
    margin-right: 40px;
    height: auto;
  }

  .specialOfferImage {
    height: 130px !important;
  }

  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px !important;
  }
  .fs-16 {
    font-size: 16px;
  }

  .offerText {
    font-size: 16px !important;
    line-height: 2.5rem !important;
    padding: 30px;
    font-weight: 400;
  }

  .offerText-b {
    font-size: 47px !important;
    font-weight: 400;
  }

  .cricbuddyAppheader {
    font-size: 17px !important;
  }

  .newLandingPage h4 {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 440px) and (max-width: 768px) {
  .firstpageimage {
    max-width: 50%;
    height: auto;
  }

  .newLandingPage h2 {
    font-size: 18px !important;
  }

  .newLandingPage h3 {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 405px) and (max-width: 767px) {
  .secondpageimage {
    max-width: 40%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 840px) {
  .secondpageimage {
    max-width: 35%;
    height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .cityText {
    font-size: 9px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 50% !important;
  }

  .inputIcons1 {
    left: 1% !important;
  }

  .inputIcons {
    left: 4% !important;
  }

  .loginbtn {
    width: 50%;
  }

  .Partnerbtn {
    width: 50%;
  }

  .Registerbtn {
    width: 50%;
  }

  .mainContainer {
    overflow: auto;
  }

  .bottomImg {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    max-width: 50% !important;
  }

  .inputIcons1 {
    left: 1% !important;
  }

  .inputIcons {
    left: 4% !important;
  }

  .loginbtn {
    width: 50%;
  }

  .Partnerbtn {
    width: 50%;
  }

  .Registerbtn {
    width: 50%;
  }

  .mainContainer {
    overflow: auto;
  }

  .bottomImg {
    width: 50%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 50% !important;
  }

  .inputIcons1 {
    left: 1% !important;
  }

  .inputIcons {
    left: 4% !important;
  }

  .loginbtn {
    width: 50%;
  }

  .Registerbtn {
    width: 50%;
  }

  .Partnerbtn {
    width: 50%;
  }

  .mainContainer {
    overflow: auto;
  }

  .footerClass {
    top: calc(100% - 72px) !important;
  }

  .bottomImg {
    width: 50%;
  }

  .editImage {
    height: 25px;
    width: 25px;
  }

  .editicon {
    width: 25%;
  }

  .sticky-button {
    position: fixed;
    z-index: 100;
    bottom: 0px;
  }

  .venuePhotos {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 250px;
    border-radius: 16px 16px 0px 0px;
    object-fit: cover;
  }

  .venuePhotos1 {
    max-height: 334px;
  }

  .heartIcon {
    width: 2rem;
    float: right;
    height: 30px;
    position: absolute;
    top: 66px;
    right: 40px;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .heartImage {
    width: 2rem;
    float: right;
    height: 30px;
    position: absolute;
    top: -16px;
    right: 27px;
    border-radius: 50%;
    background: white;
    padding: 4px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  #checkboxContainer {
    z-index: 1;
    top: 37px;
    left: 365px;
  }

  .dayPartOpen {
    background: white;
    border-radius: 8px;
    border: none;
    padding: 19px;
    height: 245px;
    width: 50%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  }

  .firstpageimage {
    max-width: 26%;
    height: auto;
  }

  .secondpageimage {
    max-width: 20%;
    height: auto;
  }
}

.allMatcesSelect {
  width: 80px;
  overflow: hidden;
}

.allMatcesSelect select {
  text-indent: 1px;
  text-overflow: "";
  width: 110px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  padding-right: 0.7 rem;
  background: transparent
    url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
    no-repeat 100% center !important;
}

.LM2 select {
  width: 122px !important;
}

:focus-visible {
  outline: none;
}

.font1 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #a51e22;
  text-transform: uppercase;
  cursor: pointer;
}

.font2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #a51e22;
  text-transform: uppercase;
}

.card-header {
  border-bottom: 1px solid #000000 !important;
}

@media only screen and (min-width: 360px) and (max-width: 380px) {
  .upcomingbtnFont {
    font-size: 13px !important;
  }

  .textLabel {
    font-size: 12px;
  }

  .font1 {
    font-size: 20px;
  }

  .footerClass {
    top: calc(100% - 72px) !important;
  }

  .loginbtn {
    font-size: 16px;
  }

  .Registerbtn {
    font-size: 16px;
  }

  .Partnerbtn {
    font-size: 16px;
  }

  .btnPadding {
    padding: 18px !important;
  }
}

.underLine {
  text-decoration: underline;
}

.profileImage {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.profileImageDiv {
  height: 120px;
  width: 120px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.userProfileImage {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

.userProfileImageDiv {
  height: 60px;
  width: 60px;
}

.matchListItem {
  background: #f3f3f3 !important;
  color: black;
  font-family: "Poppins";
}

.playersScoreCard {
  background: #3c3c40 !important;
  color: white;
  font-family: "Poppins";
}

.scorecardList {
  background-color: #f3f3f3 !important;
  border-radius: 1.25rem !important;
}

.btn {
  box-shadow: 0px 0px 5px rgba(48, 48, 60, 0.16);
  border-radius: 5px;
  width: -webkit-fill-available;
}

.checkbox {
  width: 1.3em !important;
  height: 1.3em !important;
}

.Lfont1 {
  font-weight: 600;
  font-size: 18px;
}

.Lfont2 {
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
}

.Lfont3 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.Lfont4 {
  font-weight: 500;
  font-size: 16px;
}

.Lfont5 {
  font-weight: 600;
  font-size: 14px;
}

.Lfont6 {
  font-weight: 500;
  font-size: 13px;
}

.Lfont7 {
  font-weight: 500;
  font-size: 14px;
}

.rowPadding {
  padding: 0 12px 0 12px;
}

.runColor {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.runsColor {
  background-color: white;
  color: #000000;
}

.fourboundarycolor {
  background-color: #fd9923 !important;
  color: #ffffff !important;
}

.sixboundarycolor {
  background-color: #4bae4f !important;
  color: #ffffff !important;
}

.out {
  background-color: #f34235 !important;
  color: #ffffff !important;
}

.btn:focus {
  box-shadow: 0px 0px 5px rgba(48, 48, 60, 0.16) !important;
}

.visibilityhidden {
  visibility: hidden;
}

.ContainerPage_3Select select {
  -webkit-appearance: none;
  appearance: none;
  background: transparent
    url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat 100% !important;
  border: none;
  text-indent: 1px;
  text-overflow: "";
  width: 75px;
}

.ContainerPage_4Select select {
  -webkit-appearance: none;
  appearance: none;
  background: transparent
    url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat 100% !important;
  border: none;
  text-indent: 1px;
  text-overflow: "";
  width: 100%;
}

.LiveContainer {
  background: #c4c4c4;
  border-radius: 15px;
}

.SelectImage1 {
  background-image: url(../../public/images/PlayerType/AllRounder.svg);
}

select.LiveScoreSelect option[value="2"] {
  background-image: url(../../public/images/PlayerType/AllRounder.svg);
}

.nav-tabs .nav-link {
  border: none !important;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
}

.nav-tabs .nav-link.active {
  border-bottom: 4px solid #a51e22 !important;
  color: #a51e22 !important;
}

.nav {
  justify-content: space-around;
}

.textClear {
  border: none;
  background-color: transparent;
  width: 25%;
}

.ContainerPage_3 {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.page_3Font1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.page_3Font2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.OutSelect {
  line-height: 0.9 !important;
}

.cursorPointer {
  cursor: pointer;
}

.accordion-item {
  border-radius: 6px !important;
}

.accordion-button-parent {
  padding: 10px 10px !important;
  background-color: #f2f2f2 !important;
  color: #000 !important;
  z-index: 0 !important;
  border-radius: 6px !important;
}

.accordion-button-child {
  padding: 10px 10px !important;
  background-color: #f5f5f5 !important;

  color: #000 !important;
  z-index: 0 !important;
  border-radius: 6px !important;
  width: 40px !important;
}

.accordion-button:not(.collapsed)::after {
  margin-bottom: 20px;
}

.playerProfilePhotoScoreCard {
  top: -80px;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 15px;
  background-size: contain;
  overflow: hidden;
  background-color: #e8e8e8;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 99999;
}

.csfont1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px;
  color: #a51e22;
}

.csfont2 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13px;
  color: #3c3c40;
}

.bottomColor {
  border-bottom: 3px solid rgb(165, 30, 34);
}

.offcanvas-end {
  width: 260px !important;
}

.dektopimag {
  width: 0;
}

.Desktoplink {
  margin-top: 10px;
}

.navclsbtn {
  border: none;
  background-color: transparent;
}

.topotp {
  top: 9% !important;
}

.offcanvas-header .RSPBprogressBar {
  margin: 0px !important;
  background-color: white !important;
  height: 5px;
}

.NavbarProfileImageDiv {
  width: 120px;
  height: 85px !important;
  border-radius: 50%;
}

#paymentReciept {
  display: none;
}

.printRecieptTitle {
  display: none !important;
  border-bottom: 1px solid #3c3c40;
}

@media print {
  .footerClass {
    display: none;
  }

  .buttonDiv {
    display: none;
  }

  .printRecieptTitle {
    display: block !important;
  }
}

.cancleLinkBtn {
  float: right;
  color: #4d89c5;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  text-decoration: underline;
}

.playerProfileimage {
  width: 100px;
  height: 100px !important;
  border-radius: 50%;
}

.TextAlignClass {
  text-align: left !important;
}

.display-center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  transform: rotate(-180deg);
}

._2Jtxm._35Ago ._2kL0S {
  background-color: white !important;
  color: black !important;
  border: 1px solid #a51e22 !important;
  animation: _JAh3L 400ms ease forwards !important;
}

._2Jtxm._2ZUAI ._2JvrO {
  background-color: #a51e22 !important;
}

._2Jtxm::before {
  content: "";
  position: absolute;
  top: 50%;
  height: 3px;
  width: 100%;
  right: 50%;
  transform: translateY(-50%);
  z-index: -1;
  background: linear-gradient(to left, #e7eaf3 50%, #a51e22 50%) !important;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 200ms ease-in-out;
}

.venueInformationHeader {
  font-size: 18px;
  border-radius: 6px;
  background-color: #f2f2f2;
  padding: 10px;
  font-weight: 600;
}

.venueInformationBody {
  padding: 1rem 1.25rem;
  background-color: #f9f9f9;
  border-radius: 6px;
}

.btn-gray {
  color: white !important;
  background-color: #828282 !important;
  box-shadow: 0px 0px 5px rgba(48, 48, 60, 0.16);
}

.customize-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #212529;
  background-color: #f7f7f7;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.5px solid #e0e0e0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.filterButtons {
  border-radius: 6px;
  padding: 4px 0px;
  color: white;
  border: none;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 11px;
}

.custom-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.citiesbox {
  width: 100%;
  padding: 4px;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: 600;
  background: #f3f1f1;
  color: inherit;
}

.citiesbox.active {
  background: #4caf50;
  color: white;
}

.footerbookmark {
  font-size: 16px;
}

.timings {
  color: #525252;
  font-weight: 500;
}

.colorClass {
  color: #a51e22;
  font-weight: 500;
  font-family: "Poppins";
}

.venueName {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins" !important;
}

.venueRate {
  font-size: 14px;
  font-weight: 500;
}

.saveVenue {
  height: 70px;
}

.search-icon {
  margin-top: -63px;
  margin-left: 12px;
  height: 20px;
}

.target-icon {
  margin-top: -32px;
  margin-right: 16px;
  float: right;
  cursor: pointer;
  color: #a51e22;
}

.target-icon :hover {
  color: #a51e22 !important;
  text-decoration: underline !important;
}

.cursorClass {
  cursor: pointer;
}

.locationSearch {
  position: absolute;
  margin-left: 12px;
  margin-top: -28px;
}

.filtersOpen {
  background: #f3f3f3;
  border-radius: 10px;
  border: none;
}

.savedVenueContainer {
  background: #f7f7f7;
  padding: 20px;
}

.savedVenueText {
  font-weight: 500;
  font-size: 17px;
  color: #3c3c40;
}

.surfacesportsclass {
  color: #a51e22;
  font-weight: bold;
}

.venuePlaceArrow {
  cursor: pointer;
  left: 10px;
  top: 3px;
}

.venuePlaceName {
  color: #a51e22;
  font-weight: bold;
  font-size: 18px;
  font-family: "Poppins";
}

.booking {
  border-radius: 0px;
  background: #a51e22;
}

.bookingList {
  border-radius: 8px;
  background: #4caf50;
}

.results-list {
  position: absolute;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  top: 38px;
}

.search-result {
  padding: 10px 10px;
}

.search-result:hover {
  background-color: #efefef;
}

.time-slot {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.time-slot:hover {
  background-color: #f0f0f0;
}

.time-dropdown {
  z-index: 1;
  right: 0px;
  top: 45px;
  width: -webkit-fill-available;
}

.slot-active {
  background-color: #a51e22;
  color: #fff;
}

.adminCard {
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  background: #f7f7f7;
  padding: 15px;
}

.adminText {
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins" !important;
  color: black;
}

.sort {
  position: absolute;
  margin-left: 85px;
  margin-top: -53px;
}

.parent-container {
  height: 100vh;
  overflow-y: auto;
}

.paymentText {
  color: #525252;
  font-family: "Poppins" !important;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.custom-placeholder::placeholder {
  font-style: normal;
}

.saveText {
  color: #339900;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.dashboardImage {
  margin-top: 29px;
}

.venueButtons {
  box-shadow: 0px 0px 3px 0px grey;
  color: inherit !important;
}

.bookingText {
  font-weight: 400;
  font-family: "Poppins";
}

.colorCommon {
  color: #a51e22;
}

.dotted-line {
  border-top: 1px dashed black;
  margin-top: 18px;
}

.ownerbuttons {
  padding: 12px;
  border-radius: 6px;
  color: white;
  border: none;
  font-family: "Poppins";
  font-size: 13px;
}

.borders {
  border-bottom: 1px solid #e0e0e0;
}

.bookingdetails {
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 400;
  color: #3c3c40;
}

.bookingans {
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 400;
  color: black;
}

.timings-span {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 10px;
  color: #3c3c40;
}

.dayPartText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #3c3c40;
}

.datepicker-native {
  position: relative;
}

.datepicker-native-input {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
}

.datepicker-native-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

.accordion-button-parent::after {
  display: none !important;
}

.approvetime {
  border: 1px solid #ced4da;
  padding: 5px;
  border-radius: 5px;
}

.closeicon {
  float: right;
}

.descText {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: #989898;
}

.dashboardimageclass {
  position: absolute;
  top: -32%;
  left: 50%;
  transform: translateX(-50%);
}

.react-bootstrap-time-picker-dropdown {
  max-height: 200px;
  /* Adjust the height as needed */
}

.adminlist label {
  color: #a51e22;
  font-family: Poppins;
  font-weight: 600;
}

.tableheaderBackground {
  background-color: #d2d2d2 !important;
}

.tableheadingBackground {
  background-color: #5b5b5b !important;
}

.show-more {
  color: #a51e22;
  font-weight: 700;
}

.sortImage {
  position: absolute;
  top: 0;
  right: 20px;
  height: 13px;
}

#filtering {
  z-index: 1;
  right: -8px;
  top: 1px;
}

.filtersBox {
  background: white;
  border-radius: 10px;
  border: 1px solid #aeaeae;
}

#filters {
  z-index: 1;
  right: 0px;
  top: 48px;
}

.contactus input {
  border: 0.5px solid #d2d2d2;
  padding: 5px;
}

.contactus textarea {
  border: 0.5px solid #d2d2d2;
  padding: 5px;
}

.contactus label {
  color: #a51e22;
  font-size: 15px;
  font-weight: 600;
}

.Contactusbtn {
  background-color: #a51e22;
  border: none;
  color: white;
  border-radius: 6px;
  padding: 5px 12px;
}

@media only screen and (max-width: 600px) {
  .accordions-button {
    font-weight: 500;
    font-size: 16px !important;
  }

  .accordion-body {
    hyphens: auto;
    -webkit-hyphens: auto;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: justify;
    word-spacing: -0.02em;
  }
}

.accordions-button:focus {
  z-index: 3;
  border-color: none !important;
  box-shadow: none !important;
}

.accordions-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-weight: 600;
  font-size: 21px;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-item {
  color: none;
  background-color: #f0f0f061 !important;
  border: none;
  border-radius: 10px !important;
}

.accordions-button::after {
  background-image: url(/public/images/plus.png) !important;
  transition: none !important;
}

.accordions-button:not(.collapsed)::after {
  background-image: url(/public/images/minus.png) !important;
  transition: none !important;
}

.accordions-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
}

.accordions-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordions-button:not(.collapsed) {
  color: #3e4349 !important;
  box-shadow: none !important;
}

.cricbuddyHeader {
  font-weight: 600;
  font-family: "Poppins";
  color: white;
}

.cricbuddytext {
  font-weight: 500;
  font-family: "Poppins";
  color: white;
}

.applayout {
  padding: 35px;
  margin: 0;
  border: 0;
  background-image: url("../../public/images/backgroundimage.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.withdrawIcons {
  font-size: 14px;
  font-weight: 500;
}

.withdrawIcons label {
  color: #a51e22;
}

.withdrawIcons img {
  height: 14px;
  width: 14px;
}

.btn-withdraw-history {
  border-radius: 4px;
  background: #000000;
  border: none;
  color: white;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.btn-withdraw {
  border-radius: 4px;
  background: #a51e22;
  border: none;
  color: white;
  padding: 5px;
  font-size: 14px;
  width: 100%;
}

.withdrawHeader {
  background: #a51e22;
  color: white;
}

logo-slider {
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider div {
  display: flex;
  animation: marquee 30s linear infinite;
}

logo-slider img {
  display: block;
  min-width: 70px;
  margin: 0;
}

logo-slider {
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
}

logo-slider div {
  display: flex;
  animation: marquee 30s linear infinite;
}

logo-slider img {
  display: block;
  min-width: 70px;
  margin: 0;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.landingpagehero {
  position: absolute;
}

.landingpagehero h1 {
  font-size: 30px;
  font-weight: 500;
  color: #222222;
}

.landingpagehero p {
  font-size: 30px;
  font-weight: 500;
  color: #222222;
}

.landingpagehero label {
  font-size: 20px;
  font-weight: 700;
  color: #a51e22;
}

.landingpageborder {
  box-shadow: 0px 2px 6px 2px #3c404326;
  border: 1px solid #3c404326;
  box-shadow: 0px 1px 2px 0px #3c40434d;

  border-radius: 15px;
}

.landingpageborder h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}

.landingsearch {
  border: 1px solid #000000;
  border-radius: 62.42px;
  padding-left: 10px;
}

.btn-search {
  background: #a51e22;
  border-radius: 62.42px;
  border: none;
  box-shadow: 1.95px 1.95px 2.6px 0px #00000026;
  width: 100%;
  color: white;
}

.popularlanding {
  background: white;
  color: #3c3c40;
  border: 1px solid #3c3c40;
  border-radius: 7.31px;
  padding: 5px;
}

.landingpageborder label {
  color: #a51e22;
}

.custom-list {
  list-style-type: none;
}

.custom-list li {
  background-image: url("../../public/images/cricketball.svg");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
  line-height: 1.5;
  color: #3e4349;
}

.owners {
  color: #000000 !important;
  border: 1px dashed !important;
  opacity: 1 !important;
}

.landingpageborder p {
  font-size: 28px;
  font-weight: 500;
}

.landingfooter h4 {
  font-size: 26px;
  font-weight: 600;
  color: #000000;
}

.landingfooter label {
  color: #a51e22;
}

.footertext span {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.left-20 {
  left: 20px;
}

.footerlinks a {
  text-decoration: none !important;
  color: #a51e22 !important;
  font-weight: 700 !important;
  font-size: 20px;
}

.footerlinks {
  font-size: 20px;
}

.playlist {
  border-right: 1px solid #3c404326;
}

.w-15 {
  width: 15%;
}

.landingfooter img {
  max-height: 30px !important;
}

.footertext img {
  max-height: 15px !important;
}

.btn-faq {
  border: none;
  background: #a51e22;
  color: white;
  padding: 5px;
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .footerlinks a {
    font-size: 12px !important;
  }

  .alert-Banner {
    font-size: 10px !important;
  }

  .footerbookmark {
    font-size: 10px !important;
  }

  .playlist {
    border: none !important;
  }

  .footerlinks {
    font-size: 12px !important;
  }

  .landingpagehero {
    position: relative;
    text-align: center;
  }

  .landingfooter img {
    max-height: 30px !important;
  }

  .results-list {
    z-index: 1;
  }

  .landingpagehero h1 {
    font-size: 20px !important;
  }

  .landingpagehero p {
    font-size: 20px !important;
  }

  .landingpageborder h2 {
    font-size: 16px !important;
  }

  .list h3 {
    font-size: 16px !important;
  }

  .list {
    border: none !important;
  }

  .custom-list li {
    font-size: 14px !important;
  }

  .landingpageborder p {
    font-size: 16px !important;
  }

  .landingfooter h4 {
    font-size: 16px !important;
  }

  .footertext span {
    font-size: 12px !important;
  }

  .footertext img {
    height: 12px !important;
  }

  logo-slider img {
    max-height: 200px !important;
  }

  .landingpageborder img {
    max-height: 300px;
  }

  .landingpageborder {
    border-radius: 0px !important;
  }

  .durationSelection {
    min-width: 30% !important;
  }

  .target-icon {
    margin-top: 0px;
    margin-right: 0px;
    float: right;
  }

  .newLandingPage h1 {
    font-size: 20px !important;
  }
}

.cityText {
  font-size: 12px;
}

.privacy-policy-font1 {
  font-family: Poppins;
  font-size: 33px;
  font-weight: 400;
}

.privacy-policy-font2 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

.privacy-policy-border {
  border-bottom: 2px dashed #bbbbbb !important;
}

.privacy-policy-ol1 li::marker,
.privacy-policy-ol1 h2 {
  font-family: Poppins;
  font-size: 23px;
  font-weight: 500;
  color: #a51e22;
}

.privacy-policy-ol2 li::marker,
.privacy-policy-ol2 h3 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #3c3c40;
}

.privacy-policy-ol2 li,
.privacy-policy-mb-2 li {
  margin-bottom: 20px;
}

.privacy-policy-ol1,
.privacy-policy-ol2 {
  word-wrap: break-word;
  text-align: justify;
}

.venuePhotos2 {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 250px;
  border-radius: 16px;
  object-fit: cover;
}

.venuePhotos3 {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 400px;
  border-radius: 16px;
  object-fit: cover;
}

.Carousel-radius {
  border-radius: 16px;
}

.Carousel-radius .lazy-load-image-background {
  display: block !important;
}

.google-width {
  width: 40px;
}

.bookingrequestdiv {
  background: #f5f5f5;
  color: #a51e22;
}

.bookingrequestheading {
  font-size: 20px;
}

.delete-account {
  font-size: 18px;
  text-decoration: underline;
  color: red;
  font-weight: 400;
  cursor: pointer;
}

.rdrDateDisplayWrapper {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: auto !important;
  border-right: none !important;
}

.rdrCalendarWrapper {
  display: block !important;
  flex-direction: initial !important;
  margin-top: 17px;
  margin-left: -6px;
}

.surface-item {
  background: #f3f1f1;
}

.surface-item span,
.surface-items span {
  color: #525252;
  font-size: 14px;
}

.surface-item label {
  color: #747373;
  font-size: 14px;
}

.surface-item p {
  color: #4caf50;
  font-size: 14px;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.newsportbtn {
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.active-time {
  background: #a51e22 !important;
  color: white !important;
  font-weight: 600 !important;
}

.disclaimerbox {
  border: 1px solid black;
}

ul {
  padding-left: 0.5rem !important;
}

.durationSelection {
  background-color: #a51e22 !important;
  min-width: 20%;
  color: white !important;
}

.NewAvailableMatchDiv {
  background-color: #f9f9f9;
  box-shadow: 0px 9px 12px 4px #00000026;
  border-radius: 25px;
  cursor: pointer;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.NewAvailableMatchDiv:hover {
  background-color: rgba(255, 0, 0, 0.5);
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.NewAvailableMatchDiv:hover .availableMatchTxtDiv,
.NewAvailableMatchDiv:hover .descText {
  color: white;
  z-index: 10;
  position: relative;
}

.firstboxcss {
  position: absolute;
  bottom: 0;
  right: 0;
}

.secondboxcss {
  position: absolute;
  top: 0;
  left: 0;
}

.thirdboxcss {
  position: absolute;
  bottom: 0;
  left: 0;
}

.fourthboxcss {
  position: absolute;
  top: 0;
  right: 0;
}

.venue-registration h1 {
  color: #a51e22;
  font-weight: 400;
}

.greyBox {
  border: 2px solid #e4e4e4;
  box-shadow: 0px 2px 8px 0px #63636333;
  border-radius: 20px;
}

.cityName {
  background: #e28b85;
  color: white;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.city-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.city-grid > div {
  flex: 1 1 calc(50% - 10px);
}

.venueBannerbtn {
  width: 100%;
  padding: 10px;
  border: 1px dashed #c4c4c4;
  text-align: center;
  color: #535353b2;
  background: white;
}

.venueBannerCross {
  position: absolute;
  right: 18px;
  padding: 2px;
  top: 5px;
  border-radius: 50%;
  background: white;
}

.areaBtn {
  background: #f3f3f3;
  border: none;
  color: #5c5c5c;
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
}

.areaBtn.active {
  background: #a51e22;
  border: none;
  color: white;
}

.scrollable-container {
  max-height: 200px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  cursor: grab;
}

.scrollable-container.active {
  cursor: grabbing;
}

.areaBtn {
  white-space: nowrap;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.scrollable-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.specialOffer {
  background-color: #a51e221a;
  border-radius: 10px;
}

.newdiv {
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.googlemapButton {
  background: #eeeeee;
  border: none;
  border-radius: 8px;
  padding: 4px;
  position: absolute;
  bottom: 2%;
  right: 1%;
  z-index: 9;
}

.custom-nav-underline .nav-link.active {
  color: #a51e22 !important;
}

.custom-nav-underline .nav-link {
  font-size: 13px;
}

.courtNameDiv {
  background-color: #f3f3f3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.specialOfferImage {
  height: 288px;
  width: 40%;
}

.courtnameText {
  color: #545454;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-400 {
  font-weight: 400;
}

.courtPrice {
  font-weight: 600;
  color: #4caf50;
}

.booknowButton {
  border: none;
  border-radius: 8px;
  background: #a51e22;
  color: white;
  padding: 6px 0px;
  font-family: "Poppins" !important;
}

.basic-single {
  z-index: 9;
}

.newLandingPage h1 {
  font-size: 32px;
}

.newLandingPage p {
  font-size: 16px;
}

.newLandingPage h2 {
  font-size: 28px;
  color: #393a3b;
}

.newLandingPage h3 {
  font-size: 24px;
  color: #393a3b;
}

.newLandingPage h4 {
  font-size: 22px;
  color: #2e2e2e;
}

.newLandingPage label {
  font-size: 16px;
  color: #3c3c40;
  font-weight: 700;
}

.booknowText {
  font-weight: 700;
  color: #a51e22 !important;
}

.underline-half {
  position: relative;
  text-decoration: none;
}

.underline-half::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 26px;
  width: 50%;
  height: 1px;
  background-color: #a51e22;
}

.custom-list-div {
  border-radius: 17px;
}

.list-text {
  color: #3e4349;
  font-weight: 500;
  font-size: 16px;
}

.contactusDiv {
  border: 2px dashed #a51e22;
}

.customLabel {
  padding: 0 3px;
  background: white;
  position: absolute;
  top: 13px;
  left: 32px;
  font-size: 14px !important;
}

.customInput {
  padding: 0.7rem 1.1rem !important;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  border-radius: 9px;
  border: 1px solid #9f9f9f;
  outline: none;
  color: inherit;
}

.accordion-item-landing-page {
  color: none;
  background-color: white !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 3px 0px #1018281a;
}

.accordian-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 75%;
}

.downloadAppDiv {
  background-image: url("../../public/images/offerbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
}

.offerText {
  font-size: 36px;
  color: white;
}

.booknowDiv {
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 12px 0px #00000040;
  position: absolute;
  bottom: -12%;
  left: 7%;
  z-index: 10;
}

.cricbuddyappImg {
  position: absolute;
  top: 15%;
  right: 2%;
  height: 50vh;
}

.image-slider {
  width: 100%;
  overflow: hidden;
  /* margin-top: 20px; */
  /* padding: 20px; */
  position: relative;
}

.image-container {
  display: flex;
  animation: slide 20s infinite linear;
}

.zoom-image {
  width: 400px;
  height: auto;
  padding: 20px;
  /* margin-right: 20px; */
  transition: transform 0.5s ease;
  transform-origin: center center;
  opacity: 0.3;
  transition: transform 1s ease, opacity 1s ease;
}

.zoom-active {
  opacity: 1 !important;
  z-index: 10;
  transform: scale(1.3) !important;
  /* margin: 65px; */
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.zoom {
  opacity: 0.3;
  /* height: 100px; */
}

.text-justify {
  text-align: justify;
}

.venueOwnerLabel {
  font-size: 26px;
}

.greyBg {
  background-color: #eeeff1;
}

.sliderText {
  z-index: 100;
  right: 6%;
  /* top: 97%; */
  position: absolute;
  font-size: 22px !important;
  color: white !important;
  font-weight: 700;
  bottom: -10%;
}

.accordion-item-landing-page {
  color: none;
  background-color: white !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 1px 3px 0px #1018281a;
}

.accordian-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 75%;
}

.cursor-pointer {
  cursor: pointer;
}

.checkBoxLabel label {
  font-family: Poppins;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: left;
  color: #636366;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
}

.custom-checkbox {
  appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #636366;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.custom-checkbox:checked::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  background-color: #000;
}

.checkbox-container {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}

.videobackground {
  background: #0000001f;
  border-radius: 23px;
}

.eoboFV {
  display: none;
}

.booknowDiv select {
  color: inherit;
  border-radius: 10px;
  padding: 5px;
}

.bookingAmountInput {
  border: 1px solid #959494;
  background: #fbfbfb;
  padding: 5px;
  padding-left: 10px;
  max-width: 60px;
}

.staff-box {
  transition: box-shadow 0.3s;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}

.staff-box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.staff-action-icons-edit {
  width: 17px !important;
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
}

.staff-action-icons-delete {
  width: 17px !important;
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}

.staff-button {
  border: none;
  border-radius: 5px;
  background: #a51e22;
  color: white;
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: "Poppins" !important;
}

.staff-cancel-button {
  text-decoration: underline;
  color: #a51e22;
  cursor: pointer;
}

.StaffImgae {
  height: 65px;
  width: 65px;
  border-radius: 100%;
}

.back {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 5px;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.venueListingH1 {
  font-size: 28px;
}

.venueListingBorder {
  border: 1px solid #aeaeae;
  width: 100%;
  border-radius: 30px;
  background-image: url("../../public/images/VenueListingBg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.venueListingBorder p {
  color: #3c3c40;
}

.couponBody span {
  color: #545454;
  font-weight: 500;
}

.couponHeader {
  border-bottom: none !important;
  background: #f3f0f0;
}

.btnAddCoupon {
  border: none;
  padding: 10px;
  color: white;
  background: #4caf50;
  border-radius: 10px;
}

/* latestlandingpage */
/* Booking */
.latestlandingpage-booking-bg {
  background-image: url("../../public/images/Element.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.latestlandingpage-booking-firsttext {
  /* font-size: 32px;
  font-weight: 700; */
  font-size: 35px;
  font-weight: 500;
  color: #a51e22;
  line-height: 3.5rem;
}

.latestlandingpage-booking-spantext {
  color: #a51e22;
  font-size: 16px;
}

.latestlandingpage-booking-book {
  bottom: -12%;
  background-color: #fff;
  border-radius: 15px;
  left: 7%;
  box-shadow: 2px 14px 10px 0px #a51e221a;
}

.latestlandingpage-booking-btn {
  color: #fff;
  background-color: #a51e22;
  padding: 5px 12px;
  border: none;
  border-radius: 6px;
}

/* media */
@media only screen and (max-width: 768px) {
  .latestlandingpage-booking-firsttext {
    line-height: 2.5rem;
    margin: 4% 0;
    text-align: center;
    font-size: 30px;
  }

  .latestlandingpage-booking-book-inline {
    border: 1px solid #00000033;
  }
}

/* media */
/* img and video */
.latestlandingpage-booking-img {
  height: 7vh;
  max-width: 100%;
  /* height: 70px; */
  /* width: 221px; */
}

.latestlandingpage-booking-video-bg {
  background-color: #dedede;
  border-radius: 3%;
  z-index: 1;
}

/* img and video */
/* Booking */
/* Popular Place */
.latestlandingpage-popularplace-h4 {
  font-size: 33px;
  font-weight: 500;
}

.latestlandingpage-popularplace-span {
  font-size: 14px;
  font-weight: 500;
  color: #a51e22;
  margin-top: 0.5cm;
}

/* media */
@media (max-width: 768px) {
  .latestlandingpage-popularplace-h4 {
    font-size: 25px;
    font-weight: 500;
  }
}

/* media */
/* images */
.latestlandingpage-popularplace-card-img {
  max-width: 100%;
  min-width: 100%;
  height: 30vh;
  object-fit: cover;
}

/* images */
/* Popular Place */
/* Features */
.latestlandingpage-features-h4 {
  text-align: center;
  font-size: 33px;
  font-weight: 500;
}

.latestlandingpage-features-row {
  display: flex;
  justify-content: center;
}

.latestlandingpage-features-h5 {
  font-size: 19px;
  font-weight: 500;
}

.latestlandingpage-features-ul {
  font-size: 16px;
  font-weight: 400;
}

/* media */
@media only screen and (max-width: 768px) {
  .latestlandingpage-features-img {
    height: 6.2vh !important;
  }

  .latestlandingpage-features-img-div {
    text-align: center;
  }

  .latestlandingpage-features-h5 {
    color: #a51e22;
    font-size: 16px;
  }

  .custom-list li {
    list-style-type: none;
  }

  .latestlandingpage-features-h4 {
    font-size: 25px;
    font-weight: 500;
    text-align: justify;
  }

  .latestlandingpage-features-row-sm {
    display: flex;
    flex-direction: column-reverse;
  }
}

/* media */
/* images */
.latestlandingpage-features-bg {
  background-image: url("../../public/images/Element.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.latestlandingpage-features-img {
  height: 18vh;
}

/* images */
/* Features */
/* FAQ */
.newpage-faq-h2 {
  font-size: 33px;
  font-weight: 500;
  color: #393a3b;
}

.newpage-faq-accordion-item {
  background-color: #fff !important;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 3px 0 #1018281a !important;
  /* z-index: 99 !important; */
}

.newpage-faq-accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #a51e22 !important;
  box-shadow: none !important;
}

.newpage-faq-accordion-button:not(.collapsed):hover {
  z-index: auto !important;
}

.newpage-faq-accordion-button:hover {
  z-index: auto !important;
}

.newpage-faq-p {
  z-index: 1 !important;
}

.newpage-faq-accordion-button:not(.collapsed)::after {
  background-image: url(../../public/images/minus.png) !important;
  transition: none !important;
  z-index: auto !important;
}

.newpage-faq-accordion-button::after {
  background-image: url(../../public/images/plus.png) !important;
  transition: none !important;
}

.newpage-faq-accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
  z-index: auto !important;
}

/* media */
@media only screen and (max-width: 768px) {
  .newpage-faq-h2 {
    font-size: 25px;
    padding-bottom: 5%;
    font-weight: 500;
  }

  .newpage-faq-accordion-item {
    font-size: 14px;
  }
}

/* media */
/* image */
.newpage-info-accordian-img {
  position: absolute;
  top: 0;
  height: 7vh;
  /* z-index: -1 !important; */
}

/* image */
/* FAQ */
/* latestlandingpage */

.scrollpopularsports {
  overflow: hidden;
}

@media (min-width: 1000px) {
  .navbar-desktop-only {
    display: block;
  }

  .navbar-mobile-only {
    display: none;
  }
}

@media (max-width: 1000px) {
  .navbar-desktop-only {
    display: none;
  }

  .navbar-mobile-only {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.campainDiv {
  color: #e2e2eb;
}

.campainDiv input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #e2e2eb;
  color: #e2e2eb;
  border-radius: 0px;
}

.bottom-5 {
  bottom: 10%;
}

.mw-65 {
  max-width: 65vh !important;
}

.alert-Banner {
  background: #a51e22 !important;
  color: white !important;
  border: none !important;
}

.btn-Banner {
  background: white;
  color: #a51e22;
  border: none;
  padding: 5px;
  border-radius: 6px;
  font-weight: 600;
}

.btn-Banner-header {
  background: #a51e22;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 6px;
  font-weight: 600;
}

.bg-Banner {
  background-image: url(../../public/images/Campains/FirstCampain.png);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 85dvh;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.pageNotFoundFont1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #203f59;
}

.pageNotFoundFont2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #203f59;
}

.phpModalBtn {
  /* width: -webkit-fill-available; */
  background-color: #a51e22 !important;
  border: 1px solid #a51e22 !important;
  border-radius: 47px !important;
}

.AddDurationBtn {
  background: #a51e22;
  color: white;
}

.DisableDurationBtn {
  background: #a51e2233;
  color: white;
}

.bookingCard {
  border: 1px solid #0000001a;
  padding: 5px;
  border-radius: 10px;
}

.sportSelectionImage {
  width: 263px;
  padding: 4px 83px 4px 82px;
  gap: 0px;
  border-radius: 7px;
  bottom: 10px;
  background: white;
  text-align: center;
}

.allSportDiv {
  border-radius: 11px;
  background: #e7e7e7;
}

.auditoriumBannerText {
  color: white;
  font-size: 36px;
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.auditoriumBookingText {
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.auditoriumBookingText button {
  background: #ffffff36;
  border: 0.5px solid #0000003d;
  border-radius: 11px;
  color: #1c354e;
  font-size: 20px;
  padding: 0px 56px 0px 56px;
}

.auditoriumBannerBtn {
  background: white;
  color: #1c354e;
  border: none;
  font-size: 20px;
  border-radius: 7px;
}

.FindBorder {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 18px;
}

.FindBorder p {
  color: #1c354e;
  font-size: 20px;
  font-weight: 600;
}

.FindBorder img {
  border-radius: 18px 18px 0px 0px;
}

@media only screen and (max-width: 900px) {
  .btn-Banner {
    font-size: 12px;
  }

  .appex {
    font-size: 12px;
    right: 5% !important;
  }

  .auditoriumBannerText {
    font-size: 16px !important;
  }

  .allSportDiv {
    border-radius: 11px;
    background: #e7e7e7;
  }

  .auditoriumBannerBtn {
    font-size: 12px;
  }

  .auditoriumBookingText button {
    font-size: 10px;
  }

  .FindBorder p {
    font-size: 12px;
  }

  .AuditoriumBaneerBgImg {
    min-height: 200px;
  }

  .popularSportimg {
    min-height: 100px;
  }

  .ListVenueBannerimg {
    min-height: 100px;
  }

  .auditoriumBookingText {
    left: 65%;
  }

  .getBannerFont {
    font-size: 14px !important;
  }

  .Homepagebanner {
    min-height: 120px;
  }

  .btn-Banner {
    padding: 2px 5px 2px 5px !important;
  }
}

.venueOwnerButton {
  background: #f5f5f5;
  border-radius: 2px;
  box-shadow: 0px 2px 4px 0px #0000002e;
}

.l-50 {
  left: 50%;
}
.l-40 {
  left: 30%;
}

.discount-badge {
  display: inline-block;
  background-color: #fad207;
  color: black;
  font-weight: bold;
  padding: 0px 5px;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  transform: rotate(-5deg);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.discount-badge span {
  display: inline-block;
  transform: rotate(5deg);
}

.discount-badge:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.getBannerFont {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.appex {
  right: 2%;
  color: #ffffff;
  font-weight: 600;
  bottom: 2%;
}

.discountWrapper {
  background-image: url("../../public/images/discount-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.searchVenues {
  position: absolute;
  bottom: 12px;
  left: 30px;
}

.customize-placeholder::placeholder {
  font-family: Montserrat;
  font-style: normal;
}

.Montserrat {
  font-family: Montserrat;
}

.venuePriceDetails {
  border: 1px solid #0000001a;
  background: #f2f2f2;
  border-radius: 11px;
  padding: 6px 13px 6px 13px;
  color: #5c5c5c;
  font-family: Montserrat;
  font-size: 12px;
  max-width: fit-content;
}

.venueListingBooknow {
  border: none;
  border-radius: 11px;
  color: white;
  background: #ba5457;
  padding: 6px 8px 6px 13px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.sportsImages {
  border-radius: 11px;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 6px 20px;
}

.filter-icon {
  position: fixed;
  bottom: 0;
  right: 0px;
  z-index: 99;
}

.filter-popup {
  position: fixed;
  bottom: -100%;
  left: 3px;
  right: 3px;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  z-index: 1050;
}

.filter-popup.visible {
  bottom: 0px;
  display: block;
}

.btn-group .btn-custom {
  border: 1px solid transparent;
  background-color: #f2f2f2;
  color: #3c3c40;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.btn-group .btn-custom:hover {
  border-color: transparent;
}

.btn-group .btn-check:checked + .btn-custom {
  background-color: #ba5457;
  color: white;
  border-color: transparent;
}

.sportsButton {
  background: #f2f2f2;
  border: 1px solid #0000001a;
  border-radius: 8px;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 500;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  width: 100%;
}

.activeIndex {
  background-color: #ba5457;
  color: white;
}

.venueListingFilterBtn {
  border-radius: 6px;
  padding: 10px 16px 10px 16px;
  color: white;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
}

.venueListingFilterBtn button {
  color: inherit;
  background-color: inherit;
}

.sort-option {
  padding: 12px;
  transition: background-color 0.3s;
  font-size: 14px;
}

.custom-accordion .accordion-button:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.custom-accordion .accordion-button:hover {
  background-color: transparent;
}

.custom-accordion .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.checked-img {
  filter: brightness(0) invert(1);
}

.venueListingTopMenu {
  background-color: #f5f5f5 !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 8px;
}

.sportimage {
  display: inline-block;
  border-radius: 11px;
  background: #f6f6f6;
  padding: 0px 10px;
}

.no-scroll {
  overflow: hidden;
}

.blur-bg {
  pointer-events: none;
}

.sticky-container {
  position: fixed;
  top: 68px;
  z-index: 1000;
  padding: 10px;
  left: 0;
  background: white;
  width: 100%;
}

.search-bg {
  position: relative;
  transition: all 1s ease-out;
}

.search-bg.show {
  z-index: 1051;
  position: fixed;
  left: 0;
  transform: scale(0.9);
  animation: pulse 0.3s ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0.9);
    filter: blur(3px);
  }
}
.venueListHeader {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}
.bg-f7f7f7 {
  background: #f7f7f7;
}
.venueListCard {
  border: 1px solid #faf4f4 !important;
  background: #faf4f4 !important;
}
.venueNameHeader {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
}
.form-switch .form-check-input {
  width: 1.5em !important;
  height: 0.8em !important;
  max-height: 0.8em !important;
}
.form-check {
  min-height: 1rem !important;
}
.venueListAddress {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: #4d4d4d;
}
.VenueOwnerBookOfflineBtn {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #e2e2e2;
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 600;
  background: white;
  border-radius: 4px;
  position: relative;
}
.checked-img {
  filter: brightness(0) invert(1);
}
.venueOwnerNavActive {
  background: #a51e22;
  color: white;
  border-radius: 4px;
  padding: 5px;
  transition: background 0.5s ease, color 0.5s ease, padding 0.5s ease;
}
.acctiveday {
  background: #a51e220d;
  color: #a51e22;
  border-radius: 4px;
  padding: 5px;
}
.bookingBorder {
  border: 1px solid transparent;
  border-image-source: linear-gradient(
    1.4deg,
    #f4f4f4 1.26%,
    rgba(254, 197, 61, 0.7) 98.9%
  );
  border-image-slice: 1;
  border-radius: 14px;
  box-shadow: 0px 4px 4px 0px #0000001a;
}
.info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  padding: 16px; /* Adjust padding as needed */
}

.info-card::before {
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  position: absolute;
  background: linear-gradient(to bottom, var(--gradient), #fff);
  z-index: -1;
  border-radius: 14px;
  padding-bottom: 0px;
}

.info-card-heading {
  font-size: 22px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  color: black;
}

.info-card-description {
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  color: #636466;
}

.info-card-content {
  flex: 1;
  text-align: left;
}
.EarningSelect {
  border: none;
  text-align: center;
  background: #a51e220d;
  color: #a51e22;
  padding: 10px 5px;
  border-radius: 5px;
}
.fw-500 {
  font-weight: 500;
}
.cl-A51E22 {
  color: #a51e22;
}
.cl-000000B2 {
  color: #000000b2;
}
.cl-008A45 {
  color: #008a45;
}
.bg-FAF4F4 {
  background: #faf4f4;
}
.borderRadius-6 {
  border-radius: 6px !important;
}
.custom-dropdown .dropdown-item.active {
  background: #a51e22;
}
.bordereditvenue {
  border: 1px solid #0000001a;
  border-radius: 8px;
  background: #fefefe;
}
.Editblock {
  background: #f0f0f061;
}
.uploadImageIcon {
  border: 0.5px dashed #c4c4c4;
  padding: 10px 20px;
  border-radius: 4px;
  background: white;
}
.NewAddVenueBtn {
  background: #a51e22;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  padding: 7px 13px;
}
.amentiesDiv {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 10px;
  color: #535353b2;
  display: flex;
  /* width: max-content; */
}
.bg-F0F0F061 {
  background: #f0f0f061;
  border-radius: 4px;
}
.CategoryText {
  color: #535353;
  font-size: 12px;
  font-weight: 400;
}
.EditVenueSaveBtn {
  background: #a51e22;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 14px 38px;
}
.DurationDiv {
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
}
.br-50 {
  border-radius: 50%;
}
.customAccordianBtn.accordion-button::after {
  margin: auto !important;
}
.customAccordianBtn.accordion-button-child {
  border: 1.5px solid #a51e22b2;
  box-shadow: 0px 1.5px 0px 0px #a51e22b2;
  padding: 0px 5px !important;
}
.weekdayPriceText {
  color: #535353;
  font-size: 12px;
}
.addPriceBtn {
  border: 1px solid #c4c4c4b2 !important;
  box-shadow: 0px 4px 4px 0px #0000001a;
  background: white !important;
  color: #a51e22 !important;
  font-weight: 600 !important;
}
.dashboardHeaderBg {
  background: #f6f6f6;
}
.suggestions-list {
  list-style: none;
  padding: 5px;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute; /* Adjust based on your layout */
  background: white;
  z-index: 1000;
}
.btnAddSlot {
  background-color: #f8f8f8;
  border: 1px solid #e1dddd;
  border-radius: 8px;
  color: #a51e22;
  font-size: 14px;
}
.border-b-1 {
  border-bottom: 1px solid black;
}
.selectContactBtn {
  border-radius: 5px;
  background: transparent;
  color: #a51e22;
  border: 1px solid #a51e22;
}
.referralLink {
  padding: 5px;
  border-radius: 10px;
  background: #fff;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0000004d;
}
.referralLink b {
  /* opacity: 0.5; */
  color: #0000004d !important;
  font-weight: 500;
  font-size: 14px !important;
}
.bg-faf4f4 {
  background: #faf4f4;
}
.t-10 {
  top: 10px;
}
.r-5 {
  right: 5px;
}
.referralRow {
  border-radius: 20px;
  box-shadow: 0px 5px 20px 0px #0000001a;
}
.referralRow b {
  color: #a51e22;
  font-size: 22px;
}
.shareText {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  opacity: 0.5;
  margin: 0;
}
.referBalance {
  border: 1px solid #940e0e4d;
  border-radius: 10px;
}
.referBalance small {
  opacity: 0.5;
  font-size: 16px;
}
.referBalance p {
  color: #a51e22;
  opacity: 1 !important;
  font-weight: 500;
  font-size: 22px;
  margin: 0;
}
